import { isDefined } from "@clipboard-health/util-ts";
import { type GeoLocation } from "@src/appV2/Location/types";
import { convertToGeoLocation } from "@src/appV2/Location/utils";

import { useDefinedWorker } from "./useDefinedWorker";

export function useWorkerGeoLocation(): GeoLocation | undefined {
  const worker = useDefinedWorker();
  const { coordinates } = worker.geoLocation ?? {};
  return isDefined(coordinates) ? convertToGeoLocation(coordinates) : undefined;
}
