import uniq from "lodash/uniq";

import { useDefinedWorker } from "./useDefinedWorker";

export function useWorkerQualifications() {
  const worker = useDefinedWorker();

  // There might be multiple licenses with the same name but different states
  // https://github.com/ClipboardHealth/cbh-mobile-app/pull/3849
  return uniq(worker?.licensesData?.map((license) => license.qualification) ?? []).sort();
}
