import { AgentStage } from "@src/appV2/Agents";
import { licenseDataSchema } from "@src/appV2/Agents/types";
import { z } from "zod";

export enum PaymentServiceAccountStatus {
  ACCOUNT_CREATED = "Account Created",
  ACCOUNT_NEEDS_UPDATE = "Account Needs Updating",
  STANDARD_PAYOUT_PENDING = "Standard Payouts Pending Approval",
  INSTANT_PAYOUT_PENDING = "Instant Payouts Pending Approval",
  STANDARD_PAYOUT_ENABLED = "Standard Payouts Enabled",
  INSTANT_PAYOUT_ENABLED = "Instant Payouts Enabled",
  NO_DOCUMENTS_REQUIRED = "No Documents Required",
}

export const getWorkerResponseSchema = z.object({
  _id: z.string(),
  agentId: z.string().optional(),

  specialities: z
    .object({
      hasSedationExperience: z.boolean().optional(),
      hasTrayAssemblyExperience: z.boolean().optional(),
      experience: z.array(z.string()),
    })
    .optional(),
  preference: z.object({
    distance: z.number(),
    qualification: z.string(),
    recommendedDistance: z.number().optional(),
  }),
  attendance: z.object({
    sortMeta: z.number(),
    completedShifts: z.number(),
    cancelledShifts: z.number(),
    totalShifts: z.number(),
    consecutive: z.number(),
    consecutiveCancels: z.number(),
    percentage: z.number(),
  }),
  mobile: z.record(z.unknown()),
  onboardingFlags: z
    .object({
      isSignupInitiated: z.boolean().optional(),
      isSignupCompleted: z.boolean().optional(),
      signupStage: z.string().nullish(),
      signupCompletedAt: z.string().nullish(),
      /** onboardingWorkflow is deprecated - delete from server and remove here */
      onboardingWorkflow: z.string().optional(),
    })
    .optional(),
  rating: z
    .object({
      count: z.number(),
      value: z.number(),
    })
    .optional(),
  appRatingStatus: z
    .object({
      appRatingSubmitted: z.boolean(),
      countPromptShown: z.number(),
      appRating: z.string().nullish(),
      lastTimePromptWasShown: z.string().datetime().optional(),
      countCompletedShiftsWhenLastShownPrompt: z.number(),
    })
    .optional(),
  notificationPreferences: z.object({
    urgentShiftNotification: z
      .object({
        isOn: z.boolean(),
        snoozedOn: z.string().nullish(),
        snoozedForDays: z.number().nullish(),
        notificationFrequency: z.string().nullish(),
      })
      .optional(),
    off: z.boolean(),
    push: z.boolean(),
    email: z.boolean().optional(),
    sms: z.boolean(),
    marketingPush: z.boolean().optional(),
    priorityAccessPush: z.boolean().optional(),
    placementsMarketingPush: z.boolean().optional(),
    marketingEmail: z.boolean().optional(),
    marketingSMS: z.boolean().optional(),
    long_code_sms: z.boolean(),
  }),
  name: z.string(),
  firstName: z.string().optional(),
  applicantId: z.string().optional(),
  signupExperimentId: z.string().optional(),
  lastName: z.string().optional(),
  phone: z.string(),
  socialSecurityNumber: z.string().nullish(),
  fullSocialSecurityNumber: z.string().optional(),
  rate: z.number().nullish(),
  stage: z.nativeEnum(AgentStage),
  showReferralAlert: z.boolean(),
  interestedAt: z.string().nullish(),
  noCallNoShow: z.number().optional(),
  signedDate: z.string().nullish(),
  active: z.boolean(),
  profileImageUrl: z.string().optional(),
  isNotificationAlertShown: z.boolean().optional(),
  isFirstTimeLoggedIn: z.boolean(),
  hasClaimedBonus: z.boolean().optional(),
  higherRate: z.string(),
  isTestAccount: z.boolean().optional(),
  clipboardAdvanceState: z.string().optional(),
  hideAsColleagueOnShifts: z.boolean().optional(),
  email: z.string(),
  // might be optional during onboarding
  employmentStatus: z.string().optional(),
  dob: z.string().optional(),
  referralCodeUsed: z
    .object({
      code: z.string(),
      createdAt: z.string().datetime(),
      referredBonusId: z.string().optional(),
      referrerBonusId: z.string().optional(),
      amountPaid: z.number().optional(),
      referrer: z.string(),
    })
    .optional(),
  address: z
    .object({
      manualMsa: z.boolean(),
      city: z.string(),
      region: z.string().optional(),
      state: z.string(),
      stateCode: z.string(),
      country: z.string(),
      countryCode: z.string(),
      formatted: z.string(),
      line1: z.string().optional(),
      subpremise: z.string().optional(),
      metropolitanStatisticalArea: z.string(),
      postalCode: z.string().optional(),
      streetNumber: z.string().optional(),
      streetName: z.string().optional(),
    })
    // might be optional during onboarding
    .optional(),
  /**
   * @deprecated
   * Use `useWorkerGeoLocation` instead
   */
  geoLocation: z
    .object({
      coordinates: z.tuple([z.number(), z.number()]),
      type: z.literal("Point"),
    })
    // might be optional during onboarding
    .optional(),
  license: z
    .object({
      state: z.string(),
    })
    .optional(),
  userId: z.string(),
  isFirstSession: z.boolean().optional(),
  referralCode: z.string(),
  signedContractorAgreements: z.array(
    z.object({
      agreementVersion: z.string(),
      fullName: z.string().nullish(),
      signedDate: z.string(),
    })
  ),
  requirements: z.array(
    z.object({
      _id: z.string().optional(),
      expires: z.string().nullish(),
      requirement: z.string().optional(),
      updatedAt: z.string().optional(),
    })
  ),
  uploads: z.array(z.unknown()),
  backgroundCheckApplications: z
    .array(
      z.object({
        applicationType: z.string().optional(),
        candidateId: z.string().optional(),
      })
    )
    .optional(),
  licensesData: z.array(licenseDataSchema),
  createdAt: z.string().optional(),
  qualification: z.string().optional(),
  tmz: z.string(),
  isProfilePictureUploaded: z.boolean().optional(),
  onProbation: z.boolean().optional(),
  canBeOnboardedInStripe: z.boolean().optional(),
  paymentAccountInfo: z
    .object({
      _id: z.string(),
      accountId: z.string(),
      createdAt: z.string(),
      enabled: z.boolean(),
      enabledAt: z.string().optional(),
      updatedAt: z.string(),
      autoWithdrawEnabled: z.boolean().optional(),
      withdrawalFee: z.number(),
      status: z.nativeEnum(PaymentServiceAccountStatus),
      showOnboard: z.boolean().optional(),
      gatewayTransferDisabled: z.boolean(),
      __v: z.number(),
    })
    .optional(),
  alreadyWorked: z.boolean().optional(),
  requestedAccountDeletion: z
    .object({
      requested: z.boolean().nullish(),
      createdAt: z.string().nullish(),
      previousStage: z.string().nullish(),
    })
    .nullish(),
  isIdentifiedInSegment: z.boolean().optional(),
  signupConfig: z
    .object({
      signingBonusAmount1: z.number().optional(),
      signingBonusAmount2: z.number().optional(),
      eventName: z.string().optional(),
    })
    .optional(),
  otherQualification: z.string().nullish(),
  homeHealthEnabled: z.boolean().optional(),
});

export type Worker = z.infer<typeof getWorkerResponseSchema>;
